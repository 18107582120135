import React from "react"
import { Link } from "gatsby"
import useBlogData from "../static_queries/useBlogData"
import blogListStyles from "../styles/components/bloglist.module.scss"
import Img from 'gatsby-image'

const bg = (blog, isProject) => (
    <li className={blogListStyles.li} key={blog.node.fields.slug} style={{marginBottom: '20px', padding: '0rem'}}>

      <div className={blogListStyles.list__info}>
      <Link to={`${blog.node.frontmatter.link}`} key={blog.node.id}>

        <p>{blog.node.frontmatter.title} ({blog.node.frontmatter.author})</p>
</Link>
      
        {isProject && (
        <p> - {blog.node.frontmatter.description}</p>
        )}
      </div>
    </li>
)

export default function BlogList() {
  const blogData = useBlogData()

  const journalism = blogData.filter(blog => blog.node.frontmatter.type !== 'projects');
  const projects = blogData.filter(blog => blog.node.frontmatter.type === 'projects');

  function renderBlogData() {
    return (
      <div style={{padding: '1.5rem 1.25rem'}}>
        <div style={{ width: '100%'}}><strong >Writing</strong></div>
        {journalism
          .map(blog => bg(blog))}
        <div style={{ width: '100%', marginTop: '2rem'  }}><strong>Projects</strong></div>
        {projects
          .map(blog => bg(blog, true))}
      </div>

    )
  }

  return (
    <section>
      <ul className={blogListStyles.list}>{renderBlogData()}</ul>
    </section>
  )
}

